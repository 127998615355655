<template>
    <div>
        <b-tabs>
                <b-card-actions
                title="Filters"
                model="jobs"
                action-collapse
                >
                <!--<b-card
                class="mb-2"
                title="Filters"
                action-collapse
                >-->
                    <b-card-text>
                        <b-row>
                            <!--<b-col md="2">
                                <p>Status</p>
                               <v-select
                                v-model="status"
                                :options="statusOptions"
                               />
                            </b-col>-->
                            <b-col md="2" v-show="getCurrentUser.roles[0].id !== 6">
                                <p>Branch</p>
                                <v-select
                                    v-model="branchId"
                                    :options="allBranches"
                                    label="name"
                                    :reduce="item => item.id"
                                />
                            </b-col>
                            <b-col md="2" v-show="getCurrentUser.roles[0].id !== 6">
                                <p>Technician</p>
                               <v-select
                                v-model="selectedTechnicianId"
                                :options="allTechnicians"
                                label="name"
                                :reduce="item => item.id"
                               />
                            </b-col>
                            <b-col md="2" v-show="getCurrentUser.roles[0].id !== 6">
                                <p>Customer</p>
                               <custom-autosuggest
                                    table="clients"
                                    label="client_name"
                                    :value="clientId"
                                    @changed="clientSelected"
                               />
                            </b-col>
                            <b-col md="4" v-show="getCurrentUser.roles[0].id !== 6">
                                <p>Date range</p>
                                <div class="d-flex">
                                    <date-range-picker
                                        ref="picker"
                                        v-model="dateRange"
                                        :locale-data="{ firstDay: 1, format: 'dd-mm-yyyy HH:mm:ss' }"
                                        @update="dateRangeApplied"
                                    >
                                        <template
                                            v-slot:input="picker"
                                            style="max-width: 350px;"
                                        >
                                            {{ picker.startDate | formatDate }} - {{ picker.endDate | formatDate }}
                                        </template>
                                    </date-range-picker>
                                    <b-button
                                        v-b-popover.hover="'Clear Filter'"
                                        variant="outline-primary"
                                        size="sm"
                                        class="ml-1"
                                        @click="resetFilter"
                                    >X</b-button>
                                </div>
                            </b-col>
                        </b-row>
                        <b-row class="mt-2">
                            <b-col>
                                <a class="job-list-link" @click="$router.replace({path: '/account/jobs',})">Active Jobs</a>
                            </b-col>
                        </b-row>
                    </b-card-text>
            </b-card-actions>
                <List
                    v-if="loaded"
                    model="jobs"
                    :can-delete="true"
                    :can-edit="false"
                    :can-view="true"
                    default-sort="jobs.id"
                    default-sort-order="desc"
                    :where="memberCondition"
                />
        </b-tabs>
    </div>
</template>

<script>
import {
    BTabs,
    BTab,
    BCard,
    BCardText,
    BRow,
    BCol,
    BFormGroup,
    BFormCheckbox,
    BButton,
    VBPopover,
} from 'bootstrap-vue';
import vSelect from 'vue-select';
import DateRangePicker from 'vue2-daterange-picker'
import moment from "moment";
import BCardActions from '@core/components/b-card-actions/BCardActions.vue';
import List from '../common/list';
import CustomAutosuggest from '../common/components/CustomAutosuggest.vue';
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
export default {
    components: {
        BCardText,
        List,
        BRow,
        BCol,
        CustomAutosuggest,
        /* BCard, */
        vSelect,
        DateRangePicker,
        BButton,
        BCardActions,
        BTabs,
    },
    filters: {
        formatDate: (val) => (val ? moment(val).format('YY/MM/DD') : ''),
    },
    directives: {
        'b-popover': VBPopover,
    },
    data() {
        return {
            totalContacts: 'loading...',
            totalMembers: 'loading...',
            totalLeads: 'loading...',
            totalSubscribers: 'loading...',
            createDateFilter: '',
            leadStatusFilter: '',
            leadSourceFilter: '',
            statusFilter: '',
            allTechnicians: [],
            allBranches: [],
            memberCondition: {
                where_fields: [],
                where_values: [],
            },
            clientId: 0,
            dateRange: {
                startDate: null,
                endDate: null,
            },
            profileId: 0,
            branchId: 0,
            selectedTechnicianId: 0,
            status: '',
            loaded: false,
            statusOptions: [
                                { label: 'Draft', code: 'draft' },
                                { label: 'Pending Review', code: 'pending_review' },
                                { label: 'Approved', code: 'approved' },
                                { label: 'Complete', code: 'complete' },
                                { label: 'Vir Submitted', code: 'vir_submitted' },
                            ],
        };
    },
    computed: {
        getCurrentUser() {
                return this.$store.state.auth.user;
        },
    },
    watch: {
        createDateFilter(val) {
            const endDate = this.$moment();
            const startDate = this.$moment().subtract(val, 'days');
            if (val) {
                this.memberCondition.where_fields = [
                    ...new Set([
                        ...this.memberCondition.where_fields,
                        'created_at',
                    ]),
                ];
                this.memberCondition.where_values[
                    this.memberCondition.where_fields.indexOf('created_at')
                ] = [startDate, endDate];
            } else {
                const ind = this.memberCondition.where_fields.indexOf(
                    'created_at',
                );
                this.memberCondition.where_fields.splice(ind, 1);
                this.memberCondition.where_values.splice(ind, 1);
            }
        },
        selectedTechnicianId(val) {
            if (val && val > 0) {
                this.memberCondition.where_fields = [
                    ...new Set([
                        ...this.memberCondition.where_fields,
                        'job_technicians.user_id',
                    ]),
                ];
                this.memberCondition.where_values[
                    this.memberCondition.where_fields.indexOf('job_technicians.user_id')
                ] = val;
            } else {
                const ind = this.memberCondition.where_fields.indexOf(
                    'job_technicians.user_id',
                );
                this.memberCondition.where_fields.splice(ind, 1);
                this.memberCondition.where_values.splice(ind, 1);
            }
        },
        branchId(val) {
            console.log('sss', val)
            if (val && val > 0) {
                this.branchId = val
                this.memberCondition.where_fields = [
                    ...new Set([
                        ...this.memberCondition.where_fields,
                        'jobs.technician_branch_id',
                    ]),
                ];
                this.memberCondition.where_values[
                    this.memberCondition.where_fields.indexOf('jobs.technician_branch_id')
                ] = val;
            } else {
                const ind = this.memberCondition.where_fields.indexOf('jobs.technician_branch_id');
                this.memberCondition.where_fields.splice(ind, 1);
                this.memberCondition.where_values.splice(ind, 1);
            }
        },
        status(val) {
            if (val) {
                this.memberCondition.where_fields = [
                    ...new Set([
                        ...this.memberCondition.where_fields,
                        'jobs.status',
                    ]),
                ];
                this.memberCondition.where_values[
                    this.memberCondition.where_fields.indexOf('jobs.status')
                ] = val.code;
            } else {
                const ind = this.memberCondition.where_fields.indexOf('jobs.status');
                this.memberCondition.where_fields.splice(ind, 1);
                this.memberCondition.where_values.splice(ind, 1);
            }
        },
    },
    created() {
        this.id = this.$route.params.id;
        this.fetchProfile();
        this.fetchTechnicians();
        this.fetchBranches();
        // [this.branchId] = this.getCurrentUser ? this.getCurrentUser.branch_id : [0]
        console.log('branchId', this.branchId)
        this.selectedTechnicianId = (this.getCurrentUser && this.getCurrentUser.roles[0].id === 4) ? this.getCurrentUser.id : 0
        this.loaded = true;
        this.addCompletedStatus()
    },
    methods: {
        clientSelected(val) {
            if (val) {
                this.clientId = val.id;
                this.memberCondition.where_fields = [
                    ...new Set([
                        ...this.memberCondition.where_fields,
                        'clients.id',
                    ]),
                ];
                this.memberCondition.where_values[
                    this.memberCondition.where_fields.indexOf('clients.id')
                ] = val.id;
            } else {
                // const ind = this.memberCondition.where_fields.indexOf('clients.id');
                // this.memberCondition.where_fields.splice(ind, 1);
                // this.memberCondition.where_values.splice(ind, 1);
            }
        },
        refreshList() {
            console.log('called');
        },
        fetchProfile() {
            this.$axios.get('/profile')
                .then(res => {
                    this.profileId = res.data.roles[0].id;
                })
                .catch(err => {
                    console.error(err);
                });
        },
        dateRangeApplied(val) {
            this.memberCondition.where_fields = [
                    ...new Set([
                        ...this.memberCondition.where_fields,
                        'jobs.job_date',
                    ]),
                ];
                this.memberCondition.where_values[
                    this.memberCondition.where_fields.indexOf('jobs.job_date')
                ] = [moment(val.startDate).startOf('day'), moment(val.endDate).endOf('day')];
            console.log({
                startDate: moment(val.startDate).startOf('day'),
                endDate: moment(val.endDate).endOf('day'),
            })
        },
        fetchTechnicians() {
            this.$axios.get('/users/get_technician')
                .then(res => {
                    this.allTechnicians = res.data;
                })
                .catch(err => {
                    console.error(err);
                });
        },
        fetchBranches() {
            this.$axios.get('/branches/autopopulate?column=name')
                .then(res => {
                    this.allBranches = res.data.results;
                })
                .catch(err => {
                    console.error(err);
                });
        },
        resetFilter() {
            this.loaded = false;
            this.createDateFilter = '';
            this.branchId = 0;
            this.selectedTechnicianId = 0;
            this.clientId = 0;
            this.status = '';
            this.dateRange = {
                startDate: null,
                endDate: null,
            };
            this.memberCondition = {
                where_fields: [],
                where_values: [],
            };
            this.loaded = true;
        },
        addCompletedStatus() {
            if (this.$route.query && Object.keys(this.$route.query).length > 0) {
                const whereFields = []
                const whereValues = []
                const { status } = this.$route.query
                console.log('test: ', status, this.$route.query)
                whereFields.push('jobs.status')
                whereValues.push(status)
                if (this.$route.query.bid !== '0') {
                    this.branchId = parseInt(this.$route.query.bid, 10)
                    whereFields.push('jobs.technician_branch_id')
                    whereValues.push(this.branchId)
                }
                if (this.$route.query.cid !== '0') {
                    this.clientId = this.$route.query.name
                    whereFields.push('clients.id')
                    whereValues.push(this.$route.query.cid)
                }
                this.memberCondition = { where_fields: whereFields, where_values: whereValues }
                this.status = this.statusOptions.find(stat => stat.code === status)
            } else {
                const code = 'complete'
                const whereFields = []
                const whereValues = []
                whereFields.push('jobs.status')
                whereValues.push(code)
                this.memberCondition = { where_fields: whereFields, where_values: whereValues }
                this.status = this.statusOptions.find(stat => stat.code === code)
            }
        },
    },
};
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
.custom-control.custom-checkbox {
    top: 40%;
}
.checkbox-container {
    justify-content: space-around;
    padding: 2rem 0;
}
.job-proper-display .content.app-content {
    padding-top: 2rem !important;
}
.navbar-floating .header-navbar-shadow {
    background: none !important;
}
.job-list-link {
    color: #03216B !important;
}
</style>
